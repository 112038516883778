import toastBottom from '@/components/bottom.vue'
import someAudit from '@/components/someAudit.vue'
import applyMembers from '@/components/applyMembers.vue'
import {
    getBanner,
    getNotice,
    getCategory,
    getHomeGoods,
    getUserStatus,
} from '@/utils/home.js';
import useClipboard from "vue-clipboard3"
export default {
    components: { toastBottom, someAudit, applyMembers },
    data() {
        return {
            bannerList: [],
            categaryList: [],
            noticeList: [],
            isShowGg: false,
            page: 1,
            oldPage: 0,
            goodsList: [],
            loading: false,
            finished: false,
            noticeDetail: '',
            isDaPerson: '',
            channel: 3,
            userTypeStatus: '', // isBuy=0(表示未购买种草商品),1=已购买种草商品；memberType就是会员类型,0=普通会员,1=个人达人,2=门店达人
            storeStatus: '', // status=状态(0=待审核，1=审核通过，2=审核拒绝)
        }
    },
    created() {
        this.getUserStatusFunc();
        this.isDaPerson = this.$cookies.get('isDaPerson');
    },
    methods: {
        // activated() {
        //     this.getUserStatusFunc();
        // },
        // 获取用户的身份状态
        getUserStatusFunc() {
            let data = {
                memberId: this.$cookies.get('memberId')
            }
            getUserStatus(data).then(res => {
                console.log('用户身份状态', res)
                if (res.data) {
                    this.userTypeStatus = res.data;
                    if ((this.userTypeStatus.isBuy == 0 && this.userTypeStatus.memberType == 0) || this.userTypeStatus.memberType == 2) {
                        if (this.isDaPerson == '2') {
                            this.channel = 2;
                            this.getCategoryFunc();
                        } else {
                            this.channel = 3;
                        }
                        this.getBannerFunc();
                        this.getNoticeFunc();
                        this.getHomeGoodsFunc();
                    }
                    // else {
                    //     if (!this.$cookies.get('isLogin')) {
                    //         this.$router.push({
                    //             path: '/login'
                    //         })
                    //     }
                    // }
                }
            })
        },
        // 获取banner
        getBannerFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                advertType: 1
            };
            getBanner(data).then(res => {
                console.log('banner', res)
                this.bannerList = res.data.advertList
            })
        },
        // 获取公告
        getNoticeFunc() {
            let data = {
                pageNo: 1,
                pageSize: 3,
                type: 1
            }
            getNotice(data).then(res => {
                console.log('notice', res.data)
                this.noticeList = res.data
            })
        },
        // 获取分类
        getCategoryFunc() {
            let data = {
                goodBigtype: 2,
                level: 2
            }
            getCategory(data).then(res => {
                console.log('分类', res.data)
                let arr = JSON.parse(JSON.stringify(res.data.category_list));
                if (arr.length > 4) {
                    arr.splice(4);
                }
                console.log('arr', arr)
                arr.push({
                    category_id: '',
                    category_name: '更多',
                    category_icon: require('../../assets/img/home/categary5.png')
                })
                this.categaryList = arr;
            })
        },
        // 获取商品列表
        getHomeGoodsFunc() {
            if (this.oldPage == this.page) return false;
            this.oldPage = this.page;
            let data = {
                memberId: this.$cookies.get('memberId'),
                goodName: '',
                sortMethod: 1,
                pageNo: this.page,
                pageSize: 10,
                channel: this.channel, //(0=普通专区,1=限时积分专区,2=达人专区,3非达人种草商品)
                categoryFirstId: '',
                tag: ''
            }
            getHomeGoods(data).then(res => {
                console.log('getHomeGoods', res)
                if (res.result) {
                    let arr = res.result.goodList;
                    arr.forEach(item => {
                            this.goodsList.push(item)
                        })
                        // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成
                    if (arr.length < 10) {
                        this.finished = true;
                    } else {
                        this.page++;
                    }
                } else {
                    this.finished = true;
                }
            })
        },
        // 跳转到搜索页面
        gotoPagesFunc(type, goodsid) {
            let url = '';
            let query = {};
            switch (type) {
                case 'search':
                    url = '/search';
                    break;
                case 'xs':
                    url = '/categary';
                    break;
                case 'goodsdetail':
                    url = '/goodsDetail';
                    query = {
                        goodsId: goodsid
                    }
                    break;
                case 'category':
                    url = '/categary';
                    query = {
                        category: goodsid
                    }
                    break;
            }
            this.$router.push({
                path: url,
                query: query
            })
        },
        // 查看公告
        openNoticeFunc(idx) {
            this.isShowGg = true;
            this.noticeDetail = {...this.noticeList[idx] }.concent
        },
        // 复制文案
        async copyFunc() {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard('13661957277')
                this.$toast.success("已复制联系方式")
            } catch (e) {
                console.error(e)
            }
        },
    },
}