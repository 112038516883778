<template>
  <div class="home">
    <!-- bg -->
    <div class="home-bg" v-if="(userTypeStatus.isBuy == 0 && userTypeStatus.memberType == 0) || userTypeStatus.memberType == 2"></div>
    <!-- 主要内容 -->
    <div class="home-main" v-if="(userTypeStatus.isBuy == 0 && userTypeStatus.memberType == 0) || userTypeStatus.memberType == 2">
      <div class="flex flex-between align-center home-main-search">
        <div class="flex flex-left align-center home-main-search-l" @click="gotoPagesFunc('search')">
          <img class="home-main-search-l-icon" src="../../assets/img/home/search.png" alt="">
          <input class="home-main-search-l-input" type="text" placeholder="请输入要搜索的内容" readonly>
        </div>
        <div class="flex flex-right align-center home-main-search-r" @click="gotoPagesFunc('xs')">
          <img class="home-main-search-r-icon" src="../../assets/img/home/sx.png" alt="">
          筛选
        </div>
      </div>
      <!-- banner -->
      <div class="home-main-banner">
        <van-swipe :autoplay="3000" class="home-main-banner-swiper">
          <van-swipe-item v-for="image in bannerList" :key="image">
            <img :src="image.photoUrl" class="home-main-banner-swiper-img"/>
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 20220119 升级成为种草达人任务专区 -->
      <div class="home-main-newgz" v-if="userTypeStatus.memberType == 0">
        <div class="flex flex-left align-center home-main-newgz-bg" :style="{background: 'url('+require('../../assets/img/home/dr-bg.png')+')no-repeat center center', backgroundSize: 'cover'}">
          升级成为种草达人任务专区
        </div>
        <div class="home-main-newgz-cont">
          1. 成功购买💰此任务区域的商品🛍️可以升级成为种草达人!<br/>
          2. 成功购买：确认✅收货任务区域的商品🛍️订单即可成为“灵龙种草达人🧑”<br/>
          3. 成为“种草达人🧑”的任务商品购买不可抵扣龙珠、不可使用优惠券、不会奖励购买龙珠、不会给予邀请的达人任务商品的推广奖励🧧(只有发现种草官奖励)<br/>
          4. 任务区域的商品🛍️均来自正规品牌商，如没有🚚运输问题，可以换货但不予退货📦。
        </div>
        <div class="home-main-newgz-toast">
          提示：平台拥有最终解释权，有任何相关问题可添加咨询：灵儿（13661957277）为你解答。<div class="home-main-newgz-toast-btn" @click="copyFunc">复制咨询</div>
        </div>
      </div>
      <!-- 公告 -->
      <div class="home-main-notice" v-if="noticeList.length > 0">
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
        >
          <van-swipe-item class="flex flex-left align-center " v-for="(item,index) in noticeList" :key="item.id" @click="openNoticeFunc(index)">
            <img class="home-main-notice-icon" src="../../assets/img/home/notice.png" alt="">
            <span class="home-main-notice-msg">{{item.title}}</span>
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 分类 -->
      <!-- <ul class="flex flex-between home-main-categary" v-if="isDaPerson == '2'">
        <li class="flex flex-center flex-wrap home-main-categary-list" v-for="item in categaryList" :key="item.id" @click="gotoPagesFunc('category', item.category_id)">
          <div class="home-main-categary-list-icon">
            <img :src="item.category_icon" alt="">
          </div>
          <div class="home-main-categary-list-name">{{item.category_name}}</div>
        </li>
      </ul> -->
      <!-- 种草商品 -->
      <div class="flex flex-left align-center home-main-zc">
        <img src="../../assets/img/home/zc.png" alt="" />
        <span v-if="userTypeStatus.memberType == 0">成功购买种草区商品可以升级成为种草达人！</span>
        <span v-if="userTypeStatus.memberType == 1">种草达人专享区</span>
        <span v-if="userTypeStatus.memberType == 2">门店特卖采购专区</span>
      </div>
      <!-- 商品列表 -->
      <van-list
          v-if="goodsList.length > 0"
          v-model:loading="loading"
          :finished="finished"
          :immediate-check="false"
          @load="getHomeGoodsFunc"
        >
        <ul class="flex flex-left flex-wrap home-main-goods">
          <li class="home-main-goods-list" v-for="item in goodsList" :key="item.id" @click="gotoPagesFunc('goodsdetail', item.goodId)">
            <div class="flex flex-center align-center home-main-goods-list-img" :style="{background: 'url('+item.goodPic+')no-repeat center center',backgroundSize: 'cover'}">
            </div>
            <div class="home-main-goods-list-name">
              {{item.goodName}}
            </div>
            <div class="home-main-goods-list-brand">
              品牌：{{item.brandName}}
            </div>
            <div class="flex flex-left home-main-goods-list-yh" v-if="item.coupon || item.score">
              <span class="home-main-goods-list-yh-span" v-if="item.coupon">{{item.coupon.name}}</span>
              <span class="home-main-goods-list-yh-span" v-if="item.score">得{{item.score}}龙珠</span>
            </div>
            <div class="home-main-goods-list-price">
              <div class="home-main-goods-list-price-l">
                ¥<span class="home-main-goods-list-price-l-num">{{item.price}}</span>起
                <span class="home-main-goods-list-price-l-toast">灵龙价</span>
              </div>
              <!-- <div class="home-main-goods-list-price-sale" v-if="isDaPerson == '0'">销量{{item.saleNum}}</div> -->
              <div class="home-main-goods-list-price-sale" style="text-decoration: line-through;color:#999;">原价¥{{parseFloat(item.goodsOriginalPrice/100).toFixed(2)}}</div>
            </div>
            <div class="flex flex-between align-center home-main-goods-list-sale" v-if="isDaPerson == '1' || isDaPerson == '2'">
              <div class="home-main-goods-list-sale-l" v-if="item.returnAmount > 0">
                推荐奖励 ¥{{parseFloat(item.returnAmount/100).toFixed(2)}}
              </div>
              <!-- <div class="home-main-goods-list-price-sale">销量{{item.saleNum}}</div> -->
              <!-- <div class="home-main-goods-list-price-sale" style="text-decoration: line-through;color:#999;">原价¥{{parseFloat(item.goodsOriginalPrice/100).toFixed(2)}}</div> -->
            </div>
          </li>
        </ul>
      </van-list>
      <!-- 已经到底了 -->
      <toast-bottom v-if="finished && goodsList.length > 0"></toast-bottom>
      <!-- 暂无数据 -->
      <van-empty v-if="goodsList.length == 0" description="暂无数据" />
    </div>
    <!-- 普通会员购买了种草商品 -->
    <some-audit v-if="userTypeStatus.isBuy == 1"></some-audit>

    <!-- 个人达人升级成为门店达人 -->
    <apply-members v-if="userTypeStatus.memberType == 1"></apply-members>

    <!-- 公告内容 -->
    <div class="home-popup" v-if="isShowGg">
      <div class="flex flex-column home-popup-main2">
        <div class="flex flex-center align-center toast-title">
          <span></span>公告内容<span></span>
        </div>
        <div class="toast-msg" v-html="noticeDetail"></div>
        <van-button type="default" size="large" class="toast-btn" @click="isShowGg = false;">
          已阅读
        </van-button>
        <div class="home-popup-main2-close" @click="isShowGg = false;"></div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>